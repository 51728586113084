<template>
  <div :class="{ 'sidebar': true, 'sidebar--visible': isVisible }">
    <button
      :class="{ 'sidebar__toggle': true, 'sidebar__toggle--active': isVisible }"
      @click="toggleVisibility"
    >
      <span>Menü anzeigen/verstecken</span>
      <svg-icon v-if="!isVisible" icon="menu" />
      <svg-icon v-if="isVisible" icon="close" fill="#333" />
    </button>
    <nav class="nav">
      <a href="/" class="nav__logo-link">
        <img class="nav__logo" src="../assets/logo.svg" />
      </a>
      <ul class="nav__main">
        <li v-for="menuItem in menuItems" :key="menuItem.label">
          <a class="nav__link" :href="'/' + menuItem.path">
            <span
              ><span class="fancy-text">{{ menuItem.label }}</span></span
            >
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SvgIcon from './SvgIcon.vue';

export default defineComponent({
  name: 'Nav',
  components: { SvgIcon },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    menuItems() {
      return this.$store.state.menuItems;
    },
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
  },
});
</script>

<style lang="scss">
.sidebar {
  width: $menuButtonSize;
  max-width: $menuButtonSize;
  padding-right: $regularFontSize;

  position: absolute;
  height: 100vh;
  z-index: 1;

  box-shadow: 0 0 0 rgba(0, 0, 0, 0);

  @include mq($from: tablet) {
    width: $navWidth;
    max-width: $maxNavWidth;
    flex-grow: 0;
    flex-shrink: 0;

    padding-right: 0;
    position: relative;
    box-shadow: $bigBoxShadow;
  }

  &--visible {
    padding-right: 0;
    position: absolute;

    min-width: $navWidth * 3;
    max-width: $navWidth * 3;

    box-shadow: $bigBoxShadow;
    z-index: 20;

    .sidebar__toggle {
      transform: translate(150%, -50%);
    }

    .nav {
      left: 0;
      width: 100%;
    }
  }
}

.sidebar__toggle {
  height: $menuButtonSize;
  width: $menuButtonSize;

  border: 0;
  background: $colorDarkGrey;
  border-radius: 5%;

  position: absolute;
  top: 50%;
  right: $spacingDefault;

  transform: translate(0, -50%);

  box-shadow: 2px 2px 10px 0px rgba($colorDarkGrey, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &--active {
    background: $colorLightBackground;
  }

  span {
    @include a11y-visually-hidden;
  }
}

.nav {
  display: block;
  position: absolute;

  left: -1 * $navWidth;
  height: 100vh;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 100;

  max-width: 100%;

  background: $colorMainBackground;

  @include mq($from: tablet) {
    left: 0;

    position: relative;
  }
}

.nav__logo-link {
  display: flex;
  justify-content: center;
}

.nav__logo {
  @include fluid-type($spacingDefault, $spacingRegular, 'padding-left');
  @include fluid-type($spacingDefault, $spacingRegular, 'padding-right');
  @include fluid-type($spacingDefault, $spacingRegular, margin-top);
  @include fluid-type($spacingDefault, $spacingRegular, margin-bottom);

  width: 100%;
  box-sizing: border-box;

  max-width: 300px;
}

.nav__link {
  display: block;
  padding: $spacingRegular 0;

  @include fluid-type($defaultFontSize, $regularFontSize);
  @include fluid-type($spacingDefault, $spacingRegular, 'padding-left');
  @include fluid-type($spacingDefault, $spacingRegular, 'padding-right');
  text-decoration: none;

  color: $colorDarkGrey;
  background: transparent;

  @include fancyUnderlineHover($colorDarkenedLight);
}
</style>
