<template>
  <div class="hero">
    <h2 class="hero__title">{{ hero.title }}</h2>
    <img class="hero__image" :src="hero.image.src" :srcset="hero.image.srcSet" />
    <div class="hero__shade"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Hero',
  computed: {
    hero() {
      return this.$store.state.hero;
    },
  },
});
</script>

<style lang="scss">
.hero {
  height: 95vh;
  min-width: 100%;
  overflow: hidden;
  position: relative;

  display: flex;

  @include fluid-type($spacingRegular * -1, -$spacingBig5, margin-left);
  @include fluid-type($spacingRegular * -1, -$spacingBig5, margin-right);
}

.hero__title {
  position: absolute;
  width: 66%;

  left: 50%;
  top: 67%;

  transform: translate(-50%, -50%);

  z-index: 1;

  @include fluid-type($xxlFontSize, $xxxlFontSize);
  text-align: center;
  margin: 0;

  text-decoration: underline;

  color: $colorWhite;
  text-shadow: 2px 2px rgba($colorDarkGrey, 0.5);
}

.hero__image {
  position: relative;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}

.hero__shade {
  position: absolute;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(51, 51, 51, 1) 100%);
}
</style>
