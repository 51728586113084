
import { defineComponent } from 'vue';
import ServiceTeaser from '@/components/ServiceTeaser.vue';
import Posts from '@/components/Posts.vue';
import Tabs from '@/components/Tabs.vue';
import ProjectSlider from '@/components/ProjectSlider.vue';
import { Actions } from '../store';
import Hero from '../components/Hero.vue';
import Contact from '../components/Contact.vue';
import OverviewButton from '../components/OverviewButton.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Hero,
    ServiceTeaser,
    Posts,
    Tabs,
    ProjectSlider,
    Contact,
    OverviewButton,
  },
  beforeMount() {
    this.$store.dispatch(Actions.LoadServiceTeasers);
    this.$store.dispatch(Actions.LoadInitialPosts);
    this.$store.dispatch(Actions.LoadProjects);
  },
  computed: {
    hasHero() {
      return Object.keys(this.$store.state.hero).length > 0;
    },
    services() {
      return this.$store.state.serviceTeasers;
    },
    initialPosts() {
      return this.$store.state.posts.slice(0, 3);
    },
  },
});
