<template>
  <router-link to="/aktuelles">
    <a class="overview-button" href="/aktuelles">
      <span>Übersicht</span>
      <svg-icon icon="overview" fill="#969696" />
    </a>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SvgIcon from '@/components/SvgIcon.vue';

export default defineComponent({
  name: 'Overview Button',
  components: {
    SvgIcon,
  },
});
</script>

<style lang="scss">
.overview-button {
  color: $colorDarkGrey;
  display: flex;
  align-items: center;

  opacity: 0.67;
  transition: all 125ms ease-in-out;
  @include fluid-type($spacingRegular, $spacingBig, padding-top);
  @include fluid-type($spacingRegular, $spacingBig, padding-bottom);

  .icon {
    @include fluid-type($spacingDefault, $spacingDefault * 1.5, margin-left);

    width: $smallIconSize;
    height: $smallIconSize;

    use {
      fill: $colorDarkGreyIcon;
    }
  }

  @include onHover() {
    opacity: 1;
  }
}
</style>
