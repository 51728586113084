<template>
  <footer
    class="footer content-element--padded content-element--inverted content-element--inverted-black"
  >
    <div class="footer__copyright">&copy; {{ currentYear }}; Merki BauDienstleistungen GmbH</div>
    <div class="footer__impr">
      <router-link to="/impressum">
        <a class="footer__link" href="/impressum" @click="navigate">
          <span>
            <span>Impressum</span>
            <svg-icon icon="arrow" />
          </span>
        </a>
      </router-link>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SvgIcon from './SvgIcon.vue';

export default defineComponent({
  name: 'Footer',
  components: {
    SvgIcon,
  },
  computed: {
    currentYear(): number {
      return new Date().getFullYear();
    },
  },
});
</script>

<style lang="scss">
.footer {
  display: flex;
}

.footer__copyright {
  color: $colorDarkenedLight;
}

.footer__impr {
  text-align: right;
  flex-grow: 1;
}

.footer__link {
  @include fancyUnderlineHover(rgba($colorLightBackground, 0.33), 0);
  color: $colorDarkenedLight;
  display: inline-flex;

  align-items: center;

  span {
    @include fluid-type($spacingDefault, $spacingDefault * 1.5, margin-right);
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
  }

  .icon {
    width: $smallIconSize;
    height: $smallIconSize;
    position: relative;
    z-index: 1;
  }

  .icon use {
    fill: $colorDarkenedLight;
  }
}
</style>
