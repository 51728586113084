
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Hero',
  computed: {
    hero() {
      return this.$store.state.hero;
    },
  },
});
