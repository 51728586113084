<template>
  <div class="tabs">
    <div class="tabs-navigation__wrapper">
      <ul class="tabs-navigation__list">
        <li v-for="section in sections" :key="section.id">
          <button
            :class="[
              'tabs-navigation__button',
              isActive(section.id) ? 'tabs-navigation__button--active' : '',
            ]"
            @click="switchActive(section.id, section.content)"
          >
            {{ section.title }}
          </button>
        </li>
      </ul>
    </div>
    <div class="tabs-navigation__panel" ref="panel">
      <div
        ref="panelContent"
        :class="[
          'content-element',
          'content-element--padded',
          'tabs-navigation__panel-content',
          contentFadeClass,
        ]"
        v-html="content"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Actions } from '../store/index';

export default defineComponent({
  data() {
    return {
      active: '',
      content: '',
      contentFadeClass: '',
    };
  },
  created() {
    this.$store.dispatch(Actions.LoadSections);
  },
  watch: {
    sections() {
      this.active = this.$store.state.sections[0].id;
      this.content = this.$store.state.sections[0].content;

      if (this.$refs.panel && this.$refs.panelContent) {
        (this.$refs.panel as HTMLElement).style.minHeight = `${
          (this.$refs.panelContent as HTMLElement).getBoundingClientRect().height
        }px`; //eslint-disable-line
      }
    },
  },
  computed: {
    sections(): string {
      return this.$store.state.sections;
    },
  },
  methods: {
    isActive(id: string) {
      return id === this.active;
    },
    switchActive(id: string, content: string) {
      this.active = id;

      this.contentFadeClass = 'tabs-navigation__panel-content--fade-out';

      setTimeout(() => {
        this.content = content;
        this.contentFadeClass =
          'tabs-navigation__panel-content--fade-out tabs-navigation__panel-content--fade-in';

        setTimeout(() => {
          this.contentFadeClass = '';

          if (this.$refs.panel && this.$refs.panelContent) {
            (this.$refs.panel as HTMLElement).style.minHeight = `${
              (this.$refs.panelContent as HTMLElement).getBoundingClientRect().height
            }px`; //eslint-disable-line
          }
        });
      }, 450);
    },
  },
});
</script>

<style lang="scss">
.tabs-navigation__wrapper {
  border-bottom: 2px solid lighten($colorMiddleGrey, 25);

  @include fluid-type($spacingRegular * -1, -$spacingBig5, margin-left);
  @include fluid-type($spacingRegular * -1, -$spacingBig5, margin-right);
}

.tabs-navigation__list {
  display: flex;

  justify-content: center;
}

.tabs-navigation__button {
  background: transparent;
  border-radius: 0;
  border: none;

  @include fluid-type($spacingDefault, $spacingRegular, padding);

  cursor: pointer;
  transform: translateY(2px);
  transition: all 250ms ease-in-out;

  position: relative;

  &:after {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 2px;
    background: transparent;
    content: ' ';

    transition: background 500ms ease-in-out;
  }

  @include onHover() {
    color: $colorRed;
  }

  &--active {
    color: $colorRed;

    &:after {
      background: $colorRed;
    }
  }
}

.tabs-navigation__panel {
  transition: min-height 400ms ease-in-out;
}

.tabs-navigation__panel-content {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: all 500ms ease-in-out;

  &--fade-out {
    transform: translate3d(33%, 0, 0);
    opacity: 0;
  }

  &--fade-in {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
</style>
