
import { defineComponent } from 'vue';
import PostTeaser from '@/components/PostTeaser.vue';

export default defineComponent({
  name: 'Posts',
  components: {
    PostTeaser,
  },
  props: {
    posts: Array,
    showMore: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    divClasses() {
      const classes = ['posts'];

      if (!this.showMore) {
        classes.push('posts--all');
      }

      return classes;
    },
    ulClasses() {
      const classes = ['posts__list'];

      if (this.showMore) {
        classes.push(...['content-element--padded-left', 'content-element--padded-right']);
      } else {
        classes.push('posts__list--all');
      }

      return classes;
    },
  },
});
