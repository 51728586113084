
import { defineComponent } from 'vue';
import { Actions } from '../store/index';

export default defineComponent({
  data() {
    return {
      active: '',
      content: '',
      contentFadeClass: '',
    };
  },
  created() {
    this.$store.dispatch(Actions.LoadSections);
  },
  watch: {
    sections() {
      this.active = this.$store.state.sections[0].id;
      this.content = this.$store.state.sections[0].content;

      if (this.$refs.panel && this.$refs.panelContent) {
        (this.$refs.panel as HTMLElement).style.minHeight = `${
          (this.$refs.panelContent as HTMLElement).getBoundingClientRect().height
        }px`; //eslint-disable-line
      }
    },
  },
  computed: {
    sections(): string {
      return this.$store.state.sections;
    },
  },
  methods: {
    isActive(id: string) {
      return id === this.active;
    },
    switchActive(id: string, content: string) {
      this.active = id;

      this.contentFadeClass = 'tabs-navigation__panel-content--fade-out';

      setTimeout(() => {
        this.content = content;
        this.contentFadeClass =
          'tabs-navigation__panel-content--fade-out tabs-navigation__panel-content--fade-in';

        setTimeout(() => {
          this.contentFadeClass = '';

          if (this.$refs.panel && this.$refs.panelContent) {
            (this.$refs.panel as HTMLElement).style.minHeight = `${
              (this.$refs.panelContent as HTMLElement).getBoundingClientRect().height
            }px`; //eslint-disable-line
          }
        });
      }, 450);
    },
  },
});
