
import { defineComponent } from 'vue';

type ProjectSliderItemState = {
  isExtended: boolean;
  isMounted: boolean;
};

export default defineComponent({
  props: {
    project: Object,
    isActive: Boolean,
  },
  data(): ProjectSliderItemState {
    return {
      isExtended: false,
      isMounted: false,
    } as ProjectSliderItemState;
  },
  computed: {
    moreTextThanWrapper(): boolean {
      if ((this.$data as ProjectSliderItemState).isMounted) {
        return (
          (this.$refs.textWrapper as HTMLElement).offsetHeight <
          (this.$refs.text as HTMLElement).offsetHeight
        );
      }

      return false;
    },
    automaticMaxHeight(): any {
      if (
        (this.$data as ProjectSliderItemState).isExtended &&
        (this.$data as ProjectSliderItemState).isMounted
      ) {
        const height =
          (this.$refs.text as HTMLElement).offsetHeight >
          (this.$refs.metaList as HTMLElement).offsetHeight
            ? (this.$refs.text as HTMLElement).offsetHeight
            : (this.$refs.metaList as HTMLElement).offsetHeight;

        return {
          maxHeight: `${height}px`,
        };
      }

      return {
        maxHeight: '300px',
      };
    },
  },
  mounted() {
    (this.$data as ProjectSliderItemState).isMounted = true;
  },
  methods: {
    toggle() {
      this.isExtended = !this.isExtended;
    },
  },
  watch: {
    isActive() {
      this.isExtended = false;
    },
  },
});
