<template>
  <div :class="divClasses">
    <ul :class="ulClasses">
      <post-teaser
        v-for="post in posts"
        :key="post.id"
        :image="post.image"
        :id="post.id"
        :title="post.title"
      />
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PostTeaser from '@/components/PostTeaser.vue';

export default defineComponent({
  name: 'Posts',
  components: {
    PostTeaser,
  },
  props: {
    posts: Array,
    showMore: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    divClasses() {
      const classes = ['posts'];

      if (!this.showMore) {
        classes.push('posts--all');
      }

      return classes;
    },
    ulClasses() {
      const classes = ['posts__list'];

      if (this.showMore) {
        classes.push(...['content-element--padded-left', 'content-element--padded-right']);
      } else {
        classes.push('posts__list--all');
      }

      return classes;
    },
  },
});
</script>

<style lang="scss">
.posts {
  width: 100%;

  @include fluid-type($spacingDefault, $spacingRegular, padding-bottom);

  &--all {
    overflow-x: visible;
  }
}
.posts__list {
  display: grid;
  grid-template-columns: 1fr;

  @include mq($from: tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq($from: desktop) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include fluid-type($spacingDefault, $spacingRegular, column-gap);
  @include fluid-type($spacingDefault, $spacingRegular, row-gap);
}
</style>
