<template>
  <div class="project-slider">
    <button
      class="project-slider__button project-slider__button--prev"
      v-if="!isFirst"
      @click="prev"
    >
      <span class="project-slider__button-label">Vorherige Projektreferenz anzeigen</span>
      <svg-icon class="project-slider__button-icon" icon="caret" fill="#333" />
    </button>
    <ul class="project-slider__list" :style="transform">
      <project-slider-item
        v-for="(project, index) in projects"
        :key="project.id"
        :project="project"
        :is-active="isActive(index)"
      ></project-slider-item>
    </ul>
    <button
      v-if="!isLast"
      class="project-slider__button project-slider__button--next"
      @click="next"
    >
      <span class="project-slider__button-label">Nächste Projektreferenz anzeigen</span>
      <svg-icon class="project-slider__button-icon" icon="caret" fill="#333" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProjectSliderItem from './ProjectSliderItem.vue';
import SvgIcon from './SvgIcon.vue';

type ProjectSliderState = {
  activeSlide: number;
};

export default defineComponent({
  components: {
    ProjectSliderItem,
    SvgIcon,
  },
  data(): ProjectSliderState {
    return {
      activeSlide: 0,
    } as ProjectSliderState;
  },
  computed: {
    projects() {
      return this.$store.state.projects;
    },
    isFirst() {
      return (this.$data as ProjectSliderState).activeSlide === 0;
    },
    isLast() {
      return (
        (this.$data as ProjectSliderState).activeSlide === this.$store.state.projects.length - 1
      );
    },
    transform() {
      return `transform: translate3d(-${
        (this.$data as ProjectSliderState).activeSlide * 100
      }%, 0 ,0)`;
    },
  },
  methods: {
    next() {
      (this.$data as ProjectSliderState).activeSlide += 1;
    },
    prev() {
      (this.$data as ProjectSliderState).activeSlide -= 1;
    },
    isActive(index: number) {
      return (this.$data as ProjectSliderState).activeSlide === index;
    },
  },
});
</script>

<style lang="scss">
.project-slider {
  position: relative;
  overflow: hidden;

  @include fluid-type($spacingRegular, $spacingBig * 2, padding-bottom);
}

.project-slider__button {
  height: 66vh;

  position: absolute;
  z-index: 10;

  &:before {
    display: block;
    content: ' ';

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 33vh 0 33vh 66px;
    border-color: transparent transparent transparent $colorLightBackground;
  }

  .project-slider__button-icon svg use {
    fill: #000;
  }
}

.project-slider__button--next {
  right: 0;
  top: 0;

  &:before {
    border-width: 33vh 66px 33vh 0;
    border-color: transparent $colorLightBackground transparent transparent;
  }

  .project-slider__button-icon {
    right: 0;
    left: auto;
    transform: translateY(0) rotate(0deg);
  }
}

.project-slider__button-label {
  @include a11y-visually-hidden;
}

.project-slider__button-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

.project-slider__list {
  display: flex;
  width: 100%;

  transition: all 350ms ease-in-out;
}
</style>
