<template>
  <div class="contact">
    <div class="contact__address">
      <dl class="contact__definitionlist">
        <dt>Adresse</dt>
        <dd>
          <span>Merki BauDienstleistungen GmbH</span>
          <span>Katzenbachstrasse 90</span>
          <span>8057 Zürich</span>
        </dd>
      </dl>
    </div>
    <div class="contact__contact">
      <dl class="contact__definitionlist">
        <dt>Anrufen</dt>
        <dd>
          <contact-button iconName="phone" target="tel:0041794440092" label="+41 79 444 00 92" />
        </dd>

        <dt>Mail schreiben</dt>
        <dd>
          <contact-button
            iconName="email"
            target="mailto:merki@merki-bd.ch"
            label="merki@merki-bd.ch"
          />
        </dd>
      </dl>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContactButton from './ContactButton.vue';

export default defineComponent({
  components: {
    ContactButton,
  },
});
</script>

<style lang="scss">
.contact {
  display: flex;

  flex-direction: column;

  @include mq($from: tablet) {
    flex-direction: row;
  }
}

.contact__address,
.contact__contact {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  @include mq($from: tablet) {
    flex-basis: 50%;
  }
}

.contact__address {
  @include mq($from: tablet) {
    @include fluid-type($spacingRegular / 2, $spacingBig, padding-right);
  }
}

.contact__contact {
  @include mq($from: tablet) {
    @include fluid-type($spacingRegular / 2, $spacingBig, padding-left);
  }
}

.contact__definitionlist {
  dt,
  dd {
    @include fluid-type($defaultFontSize, $regularFontSize);
  }

  dt {
    @include fluid-type($spacingDefault, $spacingRegular, padding-bottom);

    color: $colorDarkenedLight;
  }

  dd {
    margin-left: 0;
    display: flex;
    flex-direction: column;

    color: $colorWhite;

    span {
      display: block;
      @include fluid-type($spacingDefault * 0.66, $spacingRegular * 0.66, padding-bottom);
    }
  }
}
</style>
