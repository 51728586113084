
import { defineComponent } from 'vue';
import SvgIcon from './SvgIcon.vue';

export default defineComponent({
  name: 'Footer',
  components: {
    SvgIcon,
  },
  computed: {
    currentYear(): number {
      return new Date().getFullYear();
    },
  },
});
