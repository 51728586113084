
import { defineComponent } from 'vue';

import SvgIcon from '@/components/SvgIcon.vue';

export default defineComponent({
  name: 'Overview Button',
  components: {
    SvgIcon,
  },
});
