
import { defineComponent } from 'vue';
import SvgIcon from './SvgIcon.vue';

export default defineComponent({
  name: 'Nav',
  components: { SvgIcon },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    menuItems() {
      return this.$store.state.menuItems;
    },
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
  },
});
