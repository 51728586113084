<template>
  <loading v-if="!isLoaded"></loading>
  <nav-bar />
  <div class="view">
    <router-view />
    <merki-footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavBar from './components/Nav.vue';
import MerkiFooter from './components/MerkiFooter.vue';
import Loading from './components/Loading.vue';
import { Actions } from './store';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    MerkiFooter,
    Loading,
  },
  beforeCreate() {
    this.$store.dispatch(Actions.InitialLoad);
  },
  computed: {
    isLoaded() {
      return this.$store.state.loaded;
    },
  },
});
</script>

<style lang="scss">
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Light.ttf) format('truetype');
}

html,
body {
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-size: $defaultFontSize;
  height: 100vh;

  background: $colorLightBackground;
}

#app {
  display: flex;
}

.view {
  height: 100vh;
  flex-grow: 1;

  overflow-x: hidden;
  overflow-y: scroll;
  margin-left: $menuButtonSize + $spacingRegular;

  @include mq($from: tablet) {
    margin-left: 0;

    @include fluid-type($spacingRegular, $spacingBig5, padding-left);
    @include fluid-type($spacingRegular, $spacingBig5, padding-right);
  }
}
</style>
