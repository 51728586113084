<template>
  <li class="project-slider__list-item">
    <h3 class="project-slider__title">{{ project.title }}</h3>
    <div class="project-slider__image-wrapper">
      <img
        class="project-slider__image"
        :src="project.featuredImage.node.sourceUrl"
        :srcset="project.featuredImage.node.srcSet"
      />
    </div>
    <div class="project-slider__content">
      <div
        :class="[
          'project-slider__text-wrapper',
          isExtended ? 'project-slider__text-wrapper--extended' : '',
        ]"
        v-bind:style="automaticMaxHeight"
        ref="textWrapper"
      >
        <div class="project-slider__text" ref="text" v-html="project.content"></div>
        <button
          @click="toggle"
          v-if="!isExtended && moreTextThanWrapper"
          type="button"
          class="project-slider__read-more"
        >
          <span>Mehr lesen</span>
        </button>
      </div>
      <dl class="project-slider__meta" ref="metaList">
        <dt class="project-slider__meta-label">Zeitraum</dt>
        <dd class="project-slider__meta-text">{{ project.project_meta.timeframe }}</dd>

        <dt class="project-slider__meta-label">Auftraggeberin</dt>
        <dd class="project-slider__meta-text">{{ project.project_meta.client }}</dd>
      </dl>
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

type ProjectSliderItemState = {
  isExtended: boolean;
  isMounted: boolean;
};

export default defineComponent({
  props: {
    project: Object,
    isActive: Boolean,
  },
  data(): ProjectSliderItemState {
    return {
      isExtended: false,
      isMounted: false,
    } as ProjectSliderItemState;
  },
  computed: {
    moreTextThanWrapper(): boolean {
      if ((this.$data as ProjectSliderItemState).isMounted) {
        return (
          (this.$refs.textWrapper as HTMLElement).offsetHeight <
          (this.$refs.text as HTMLElement).offsetHeight
        );
      }

      return false;
    },
    automaticMaxHeight(): any {
      if (
        (this.$data as ProjectSliderItemState).isExtended &&
        (this.$data as ProjectSliderItemState).isMounted
      ) {
        const height =
          (this.$refs.text as HTMLElement).offsetHeight >
          (this.$refs.metaList as HTMLElement).offsetHeight
            ? (this.$refs.text as HTMLElement).offsetHeight
            : (this.$refs.metaList as HTMLElement).offsetHeight;

        return {
          maxHeight: `${height}px`,
        };
      }

      return {
        maxHeight: '300px',
      };
    },
  },
  mounted() {
    (this.$data as ProjectSliderItemState).isMounted = true;
  },
  methods: {
    toggle() {
      this.isExtended = !this.isExtended;
    },
  },
  watch: {
    isActive() {
      this.isExtended = false;
    },
  },
});
</script>

<style lang="scss">
.project-slider__image-wrapper {
  width: 100%;
  height: 66vh;

  position: relative;

  display: flex;
  overflow: hidden;

  order: 1;
}

.project-slider__title {
  order: 2;

  @include fluid-type($spacingDefault, $spacingRegular, padding);
}

.project-slider__image {
  object-fit: cover;

  min-width: 100%;
  min-height: 100%;
}

.project-slider__list-item {
  max-width: 100%;

  display: flex;
  flex-direction: column;
}

.project-slider__content {
  display: flex;

  order: 3;
}

.project-slider__text-wrapper {
  position: relative;

  max-height: 300px;
  overflow: hidden;

  transition: all 250ms ease-in-out;
}

.project-slider__text {
  @include fluid-type($spacingDefault, $spacingRegular, padding-left);
  @include fluid-type($spacingDefault, $spacingRegular, padding-right);
}

.project-slider__meta {
  min-width: 33%;

  @include fluid-type($spacingDefault, $spacingRegular, padding-right);
}

.project-slider__meta-label {
  color: $colorDarkGrey;

  border-top: 1px solid $colorDarkenedLight;

  @include fluid-type($spacingDefault, $spacingRegular, padding-top);
  @include fluid-type($spacingDefault, $spacingRegular, padding-bottom);
}

.project-slider__meta-text {
  @include fluid-type($spacingDefault, $spacingRegular, padding-bottom);

  margin-left: 0;

  &:last-child {
    border-bottom: 1px solid $colorDarkenedLight;
  }
}

.project-slider__read-more {
  width: 100%;

  @include fluid-type($defaultFontSize, $regularFontSize);
  @include fluid-type($spacingDefault, $spacingRegular, padding-top);

  color: $colorRed;

  position: absolute;
  bottom: 0;

  background: $colorLightBackground;

  @include onHover {
    color: $colorDarkGrey;
  }

  &:before {
    display: block;
    content: ' ';

    position: absolute;
    bottom: 0;

    height: 200px;
    width: 100%;

    background: linear-gradient(180deg, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 1) 100%);
  }

  span {
    position: relative;
    z-index: 1;
  }
}
</style>
