<template>
  <li class="post-teaser">
    <router-link :to="navigationTarget(id, title)">
      <div class="post-teaser__image-wrapper">
        <img class="post-teaser__image" :src="image.src" :srcset="image.srcSet" />
      </div>
      <div class="post-teaser__content">
        <h3 class="post-teaser__title">
          <span>{{ title }}</span>
        </h3>
      </div>
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Post Teaser',
  props: {
    image: {},
    title: String,
    id: String,
  },
  methods: {
    navigationTarget(id: string, title: string) {
      const uriTitle = encodeURI(title).replace('%20', '-');

      return `/beitrag/${uriTitle}/${id}`;
    },
  },
});
</script>

<style lang="scss">
.post-teaser {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  padding-bottom: $buttonHeight;

  width: 100%;
  min-width: 0;

  position: relative;

  &:after {
    content: ' ';
    display: block;
    top: -10px;
    right: -10px;
    width: 100%;
    height: 300px;

    background: $colorDarkenedLight;

    position: absolute;
    z-index: 0;

    transition: all 350ms ease-in-out;
  }

  @include onHover() {
    .post-teaser__image-wrapper {
      transform: translate3d($defaultFontSize / 3, 0, 0);
    }

    .post-teaser__image {
      transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1);
    }

    .post-teaser__title {
      transform: translate3d($defaultFontSize / 1.5, 0, 0);

      > span:after {
        background: $colorRed;
        opacity: 0.3;
      }
    }

    &:after {
      background-color: $colorRed;
      opacity: 0.5;
    }
  }
}

.post-teaser__image-wrapper {
  width: 100%;
  height: 300px;
  position: relative;

  z-index: 2;

  display: flex;
  overflow: hidden;

  transform: translate3d(0, 0, 0);
  transition: transform 350ms ease-in-out;
}

.post-teaser__image {
  object-fit: cover;

  position: relative;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  overflow: hidden;

  min-width: 100%;
  min-height: 100%;
}

.post-teaser__title {
  @include fancyUnderline($colorDarkenedLight);

  transform: translate3d(0, 0, 0);

  transition: transform 450ms ease-in-out;
  transition-delay: 100ms;
}
</style>
