<template>
  <a class="contact-button" :href="target">
    <svg-icon :icon="iconName"></svg-icon>
    <span class="contact-button__label">{{ label }}</span>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SvgIcon from './SvgIcon.vue';

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    target: String,
    label: String,
    iconName: String,
  },
});
</script>

<style lang="scss">
.contact-button {
  @include fluid-type($spacingDefault, $spacingRegular, padding);
  @include fluid-type($spacingDefault * 1.2, $spacingRegular * 1.2, margin-bottom);

  border: 1px solid $colorWhite;
  display: inline-flex;
  align-self: flex-start;
  align-items: center;

  .icon {
    @include fluid-type($spacingDefault / 2, $spacingRegular / 2, margin-right);
  }

  span.contact-button__label {
    padding-bottom: 0;
    color: $colorWhite;
  }

  &:hover {
    background: $colorWhite;

    span.contact-button__label {
      color: $colorRed;
    }

    .icon use {
      fill: $colorRed;
    }
  }
}
</style>
