
import { defineComponent } from 'vue';
import ProjectSliderItem from './ProjectSliderItem.vue';
import SvgIcon from './SvgIcon.vue';

type ProjectSliderState = {
  activeSlide: number;
};

export default defineComponent({
  components: {
    ProjectSliderItem,
    SvgIcon,
  },
  data(): ProjectSliderState {
    return {
      activeSlide: 0,
    } as ProjectSliderState;
  },
  computed: {
    projects() {
      return this.$store.state.projects;
    },
    isFirst() {
      return (this.$data as ProjectSliderState).activeSlide === 0;
    },
    isLast() {
      return (
        (this.$data as ProjectSliderState).activeSlide === this.$store.state.projects.length - 1
      );
    },
    transform() {
      return `transform: translate3d(-${
        (this.$data as ProjectSliderState).activeSlide * 100
      }%, 0 ,0)`;
    },
  },
  methods: {
    next() {
      (this.$data as ProjectSliderState).activeSlide += 1;
    },
    prev() {
      (this.$data as ProjectSliderState).activeSlide -= 1;
    },
    isActive(index: number) {
      return (this.$data as ProjectSliderState).activeSlide === index;
    },
  },
});
