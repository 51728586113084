import { createStore } from 'vuex';
import { request, gql } from 'graphql-request';

export enum Actions {
  InitialLoad = 'InitialLoad',
  LoadServiceTeasers = 'LoadServiceTeasers',
  LoadInitialPosts = 'LoadInitialPosts',
  LoadSections = 'LoadSections',
  LoadProjects = 'LoadProjects',
  LoadPost = 'LoadPost',
  LoadAllPosts = 'LoadAllPosts',
}

export enum Commits {
  SetMenuItems = 'SetMenuItems',
  SetHero = 'SetHero',
  SetServiceTeasers = 'SetServiceTeasers',
  SetPosts = 'SetPosts',
  SetSections = 'SetSections',
  SetProjects = 'SetProjects',
  SetSinglePost = 'SetSinglePost',
  SetLoaded = 'SetLoaded',
}

export default createStore({
  state: {
    loaded: false,
    menuItems: [],
    hero: {},
    serviceTeasers: [],
    posts: [],
    sections: [],
    projects: [],
    singlePost: {},
  },
  mutations: {
    [Commits.SetMenuItems]: (state, payload) => {
      state.menuItems = payload;
    },
    [Commits.SetHero]: (state, payload) => {
      state.hero = payload;
    },
    [Commits.SetServiceTeasers]: (state, payload) => {
      state.serviceTeasers = payload;
    },
    [Commits.SetPosts]: (state, payload) => {
      state.posts = payload;
    },
    [Commits.SetSections]: (state, payload) => {
      state.sections = payload;
    },
    [Commits.SetProjects]: (state, payload) => {
      state.projects = payload;
    },
    [Commits.SetSinglePost]: (state, payload) => {
      state.singlePost = payload;
    },
    [Commits.SetLoaded]: (state, payload) => {
      state.loaded = payload;
    },
  },
  actions: {
    [Actions.InitialLoad]: (context) => {
      const query = gql`
        query {
          menu(id: "main", idType: NAME) {
            id
            menuItems {
              nodes {
                label
                path
              }
            }
          }
          heroes {
            nodes {
              title
              featuredImage {
                node {
                  srcSet
                  sourceUrl
                }
              }
            }
          }
        }
      `;

      request('https://merki-bd.ch/cms/graphql', query).then((data) => {
        context.commit(Commits.SetMenuItems, data.menu.menuItems.nodes);
        context.commit(Commits.SetHero, {
          title: data.heroes.nodes[0].title,
          image: {
            src: data.heroes.nodes[0].featuredImage.node.sourceUrl,
            srcSet: data.heroes.nodes[0].featuredImage.node.srcSet,
          },
        });

        setTimeout(() => {
          context.commit(Commits.SetLoaded, true);
        }, 500);
      });
    },
    [Actions.LoadServiceTeasers]: (context) => {
      const query = gql`
        query {
          services {
            nodes {
              title
              content
            }
          }
        }
      `;

      request('https://merki-bd.ch/cms/graphql', query).then((data: any) => {
        context.commit(
          Commits.SetServiceTeasers,
          data.services.nodes.map((node: any) => ({
            title: node.title,
            paragraph: node.content,
          }))
        );
      });
    },
    [Actions.LoadInitialPosts]: (context) => {
      const query = gql`
        query {
          posts(first: 3) {
            nodes {
              title
              id
              featuredImage {
                node {
                  srcSet
                  sourceUrl
                }
              }
            }
          }
        }
      `;

      request('https://merki-bd.ch/cms/graphql', query).then((data: any) => {
        context.commit(
          Commits.SetPosts,
          data.posts.nodes.map((node: any) => ({
            title: node.title,
            id: node.id,
            image: {
              src: node.featuredImage.node.sourceUrl,
              srcSet: node.featuredImage.node.srcSet,
            },
          }))
        );
      });
    },
    [Actions.LoadSections]: (context) => {
      const query = gql`
        query {
          pages {
            nodes {
              id
              title
              content
            }
          }
        }
      `;

      request('https://merki-bd.ch/cms/graphql', query).then((data: any) => {
        context.commit(Commits.SetSections, data.pages.nodes);
      });
    },
    [Actions.LoadProjects]: (context) => {
      const query = gql`
        query {
          projects {
            nodes {
              id
              title
              content
              project_meta {
                client
                timeframe
              }
              featuredImage {
                node {
                  sourceUrl
                  srcSet
                }
              }
            }
          }
        }
      `;

      request('https://merki-bd.ch/cms/graphql', query).then((data) => {
        context.commit(Commits.SetProjects, data.projects.nodes);
      });
    },
    [Actions.LoadPost]: (context, postId) => {
      const query = gql`
        query {
          post(id: "${postId}") {
            id
            title
            content
            featuredImage {
              node {
                sourceUrl
                srcSet
              }
            }
            date
          }
        }
      `;

      request('https://merki-bd.ch/cms/graphql', query).then((data) => {
        context.commit(Commits.SetSinglePost, data.post);
      });
    },
    [Actions.LoadAllPosts]: (context) => {
      const query = gql`
        query {
          posts {
            nodes {
              title
              id
              featuredImage {
                node {
                  srcSet
                  sourceUrl
                }
              }
            }
          }
        }
      `;

      request('https://merki-bd.ch/cms/graphql', query).then((data: any) => {
        context.commit(
          Commits.SetPosts,
          data.posts.nodes.map((node: any) => ({
            title: node.title,
            id: node.id,
            image: {
              src: node.featuredImage.node.sourceUrl,
              srcSet: node.featuredImage.node.srcSet,
            },
          }))
        );
      });
    },
  },
  modules: {},
});
