
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Post Teaser',
  props: {
    image: {},
    title: String,
    id: String,
  },
  methods: {
    navigationTarget(id: string, title: string) {
      const uriTitle = encodeURI(title).replace('%20', '-');

      return `/beitrag/${uriTitle}/${id}`;
    },
  },
});
