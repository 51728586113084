<template>
  <svg class="icon" aria-hidden="true">
    <use :xlink:href="iconName" :style="[`--fill: ${fill}`]"></use>
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

const requireAll = (requireContext: any) => requireContext.keys().map(requireContext);
const req = require.context('../assets/icon', false, /\.svg$/);
requireAll(req);

export default defineComponent({
  name: 'SVG Icon',
  props: {
    icon: String,
    fill: {
      type: String,
      default: '#FFF',
    },
  },
  computed: {
    iconName(): string {
      return `#i-${this.$props.icon}`;
    },
  },
});
</script>

<style lang="scss">
.icon {
  width: $iconSize;
  height: $iconSize;

  &--small {
    width: $smallIconSize;
    height: $smallIconSize;
  }

  use {
    fill: var(--fill, $colorWhite);
  }
}
</style>
