<template>
  <div class="home">
    <hero v-if="hasHero" />
    <!-- CONTENT BLOCK SERVICES -->
    <div id="angebot" class="content-element content-element--padded">
      <h2><span>Angebot</span></h2>
      <ul class="service-teasers">
        <service-teaser
          v-for="service in services"
          :key="service.title"
          :title="service.title"
          :paragraph="service.paragraph"
        />
      </ul>
    </div>
    <!-- END CONTENT BLOCK SERVICES -->
    <!-- CONTENT BLOCK BLOGPOSTS -->
    <div id="aktuell" class="content-element content-element--padded-bottom content-element--full">
      <div class="home-posts--title">
        <h2><span>Aktuell</span></h2>
        <overview-button></overview-button>
      </div>

      <posts :posts="initialPosts" :showMore="true" />
    </div>
    <!-- END CONTENT BLOCK BLOGPOSTS -->
    <!-- CONTENT BLOCK ABOUT US -->
    <div id="ueber_uns" class="content-element">
      <h2><span>Über uns</span></h2>
      <tabs />
    </div>
    <!-- END CONTENT BLOCK ABOUT US -->
    <!-- CONTENT BLOCK PROJECTS -->
    <div id="projekte" class="content-element">
      <h2><span>Projekte</span></h2>
      <project-slider />
    </div>
    <!-- END CONTENT BLOCK PROJECTS -->
    <!-- CONTENT BLOCK CONTACT -->
    <div id="kontakt" class="content-element content-element--inverted content-element--padded">
      <h2><span>Kontakt</span></h2>
      <contact />
    </div>
    <!-- END CONTENT BLOCK CONTACT -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ServiceTeaser from '@/components/ServiceTeaser.vue';
import Posts from '@/components/Posts.vue';
import Tabs from '@/components/Tabs.vue';
import ProjectSlider from '@/components/ProjectSlider.vue';
import { Actions } from '../store';
import Hero from '../components/Hero.vue';
import Contact from '../components/Contact.vue';
import OverviewButton from '../components/OverviewButton.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Hero,
    ServiceTeaser,
    Posts,
    Tabs,
    ProjectSlider,
    Contact,
    OverviewButton,
  },
  beforeMount() {
    this.$store.dispatch(Actions.LoadServiceTeasers);
    this.$store.dispatch(Actions.LoadInitialPosts);
    this.$store.dispatch(Actions.LoadProjects);
  },
  computed: {
    hasHero() {
      return Object.keys(this.$store.state.hero).length > 0;
    },
    services() {
      return this.$store.state.serviceTeasers;
    },
    initialPosts() {
      return this.$store.state.posts.slice(0, 3);
    },
  },
});
</script>

<style lang="scss">
.service-teasers {
  display: grid;

  grid-template-columns: 1fr;

  @include fluid-type($spacingDefault * 1.25, $spacingRegular * 2, row-gap);

  @include mq($from: desktop) {
    column-gap: $spacingRegular * 2;
  }
}

.home-posts--title {
  display: flex;

  align-items: center;
  justify-content: space-between;

  .overview-button {
    @include fluid-type($spacingDefault, $spacingRegular, margin-right);
  }
}
</style>
