<template>
  <li class="service-teaser">
    <h3 class="service-teaser__title">{{ title }}</h3>
    <div class="service-teaser__content" v-html="paragraph"></div>
  </li>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Service Teaser',
  props: {
    title: {
      type: String,
      default: '',
    },
    paragraph: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss">
.service-teaser {
  display: flex;
  flex-direction: column;

  width: 100%;

  @include mq($from: desktop) {
    width: 66%;
  }

  &:nth-child(even) {
    justify-self: flex-end;
  }
}
.service-teaser__title {
  margin: 0;
  padding: $spacingDefault $spacingDefault;

  transform: translateX($spacingDefault);

  background: $colorRed;
  color: $colorWhite;
  align-self: flex-end;

  @include fluid-type($defaultFontSize, $regularFontSize);
}

.service-teaser__content {
  @include fluid-type($spacingRegular, $spacingBig, padding);

  border: 1px solid darken($colorLightBackground, 1.1);

  background: $colorMainBackground;
  margin-top: -$spacingDefault;
  box-shadow: $initialBoxShadow;

  p {
    margin: 0;
  }
}
</style>
