
import { defineComponent } from 'vue';
import NavBar from './components/Nav.vue';
import MerkiFooter from './components/MerkiFooter.vue';
import Loading from './components/Loading.vue';
import { Actions } from './store';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    MerkiFooter,
    Loading,
  },
  beforeCreate() {
    this.$store.dispatch(Actions.InitialLoad);
  },
  computed: {
    isLoaded() {
      return this.$store.state.loaded;
    },
  },
});
